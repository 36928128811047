/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

export default [
  {
    text: 'Dashboard Produksi',
    link: '/produksi',
    type: 'menu',
    exact: true,
    hak: ['SUPA', 'DAS_PRD'],
  },
  {
    text: 'Dashboard Marketing',
    link: '/marketing',
    type: 'menu',
    exact: true,
    hak: ['SUPA', 'DAS_MKT'],
  },
  {
    text: 'Dashboard Inventory',
    link: '/inventory',
    type: 'menu',
    exact: true,
    hak: ['SUPA', 'DAS_INV'],
  },
  {
    text: 'Dashboard Procurement',
    link: '/procurement',
    type: 'menu',
    exact: true,
    hak: ['SUPA', 'DAS_PRO'],
  },
  {
    text: 'Dashboard Penjualan',
    link: '/penjualan',
    type: 'menu',
    exact: true,
    hak: ['SUPA', 'DAS_SLS'],
  },
  {
    text: 'Dashboard SIA',
    link: '/sia',
    type: 'menu',
    exact: true,
    hak: ['SUPA', 'DAS_SIA'],
  },
];

import { DashboardApi } from 'api';
import {
  Button,
  ChartBarHorizontal,
  ChartBarVertical,
  ChartLine,
  Select,
} from 'components2';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FiShoppingCart, FiTruck } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { dateConvert, numberConvert, rupiahConvert } from 'utilities2';
import { InfoDoughnutChart, InfoItem } from './components';
import { monthOptions } from './constants';
import { generateYearOptions, sortBarangSeringDiminta } from './helpers';

const date = dateConvert();
const rupiah = rupiahConvert();
const number = numberConvert();

export const DashboardInventory = ({ setNavbarTitle }) => {
  const [filter, setFilter] = useState({
    month: date.getMonth(new Date()),
    monthName: date.getDetailMonth(new Date()),
    year: date.getYear(new Date()),
  });

  const dateSelected = `${filter.year}-${filter.month}-01`;

  const getPR = useQuery(['dashboard', 'purchase_request', dateSelected], () =>
    DashboardApi.get({
      modul: 'INV',
      part: 'purchase_request',
      tanggal: dateSelected,
      bulan: filter.month,
      tahun: filter.year,
    }),
  );

  const getSJ = useQuery(['dashboard', 'surat_jalan', dateSelected], () =>
    DashboardApi.get({
      modul: 'INV',
      part: 'surat_jalan',
      tanggal: dateSelected,
      bulan: filter.month,
      tahun: filter.year,
    }),
  );

  const getKeperluan = useQuery(['dashboard', 'keperluan', dateSelected], () =>
    DashboardApi.get({
      modul: 'INV',
      part: 'keperluan',
      tanggal: dateSelected,
      bulan: filter.month,
      tahun: filter.year,
    }),
  );

  const getNilaiKelompok = useQuery(
    ['dashboard', 'INV', 'niai_per_kelompok', dateSelected],
    () =>
      DashboardApi.get({
        modul: 'INV',
        part: 'nilai_per_kelompok',
        tanggal: dateSelected,
        bulan: filter.month,
        tahun: filter.year,
      }),
  );

  const getNilaiPersediaan = useQuery(
    ['dashboard', 'INV', 'nilai_persediaan', filter.year],
    () =>
      DashboardApi.get({
        modul: 'INV',
        part: 'nilai_persediaan',
        tanggal: dateSelected,
        bulan: filter.month,
        tahun: filter.year,
        tahun_mutasi: filter.year,
      }),
  );

  const getBarangSeringDiminta = useQuery(
    ['dashboard', 'INV', 'top_10_barang', dateSelected],
    () =>
      DashboardApi.get({
        modul: 'INV',
        part: 'top_10_barang',
        tanggal: dateSelected,
        bulan: filter.month,
        tahun: filter.year,
        tahun_mutasi: filter.year,
      }),
  );

  const sortedBarangSeringDiminta = sortBarangSeringDiminta(
    getBarangSeringDiminta?.data?.data,
  );

  useEffect(() => setNavbarTitle('Dashboard Inventory'), []);

  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          month: filter.month,
          monthName: filter.monthName,
          year: filter.year,
        }}
        onSubmit={(val) => {
          setFilter({
            month: val.month,
            monthName: val.monthName,
            year: val.year,
          });
        }}
      >
        {({ values, setFieldValue, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              {/* Bulan */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Bulan</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={monthOptions}
                    defaultValue={monthOptions.find(
                      (val) => val.value.toString() === values.month.toString(),
                    )}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        month: val.value,
                        monthName: val.label,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={generateYearOptions}
                    defaultValue={generateYearOptions.find(
                      (val) => val.value.toString() === values.year.toString(),
                    )}
                    onChange={(val) => setFieldValue('year', val.value)}
                  />
                </div>
              </div>

              {/* Button */}
              <div>
                <Button text="Filter" className="px-4" onClick={handleSubmit} />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Purchase request */}
        <Col md={6} lg={6} xl={3} className="mb-3">
          <InfoItem
            loading={getPR.isFetching}
            title="Purchase Request"
            icon={<FiShoppingCart />}
            value={getPR.data?.data ?? '-'}
          />
        </Col>

        {/* Info Surat jalan */}
        <Col md={6} lg={6} xl={3} className="mb-3">
          <InfoItem
            loading={getSJ.isFetching}
            title="Surat Jalan"
            icon={<FiTruck />}
            value={getSJ.data?.data ?? '-'}
          />
        </Col>

        {/* Presentase PR */}
        <Col className="mb-3">
          <div
            className="d-flex justify-content-between bg-white rounded border shadow-sm px-4 py-2"
            style={{ height: '100%' }}
          >
            <div className="d-flex align-items-center">
              <b style={{ fontSize: 24, lineHeight: 1.4 }}>
                Presentase PR
                <br />
                Berdasarkan Keperluan
              </b>
            </div>
            {getKeperluan.isFetching ? (
              <div className="d-flex justify-content-end align-items-center">
                Memuat data . . .
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <InfoDoughnutChart
                    title="Stok"
                    color="#0073b9"
                    datasets={[
                      {
                        data: [
                          parseInt(getKeperluan?.data?.data?.produksi ?? 0),
                          100 -
                            parseInt(getKeperluan?.data?.data?.produksi ?? 0),
                        ],
                        backgroundColor: ['#0073b9', '#00abda'],
                      },
                    ]}
                    value={parseInt(getKeperluan?.data?.data?.produksi ?? '-')}
                  />
                  <InfoDoughnutChart
                    title="Produksi"
                    color="#ed6914"
                    datasets={[
                      {
                        data: [
                          parseInt(getKeperluan?.data?.data?.stok ?? 0),
                          100 - parseInt(getKeperluan?.data?.data?.stok ?? 0),
                        ],
                        backgroundColor: ['#ed6914', '#f59e0b'],
                      },
                    ]}
                    value={parseInt(getKeperluan?.data?.data?.stok ?? 0)}
                  />
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6} className="my-3">
          <div
            className="p-3 bg-white border rounded shadow-sm"
            style={{ height: '100%' }}
          >
            <div className="text-center">
              <b>
                Nilai Persediaan Barang Bulan {filter.monthName} {filter.year}{' '}
                <br /> Berdasarkan Kelompok Barang
              </b>
            </div>
            {getNilaiKelompok.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartBarVertical
                  showLegend={false}
                  data={{
                    labels:
                      getNilaiKelompok?.data?.data?.map(
                        (val) => val?.nama_kelompok,
                      ) ?? [],
                    datasets: [
                      {
                        data:
                          getNilaiKelompok?.data?.data?.map(
                            (val) => val?.summary,
                          ) ?? [],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>
        <Col md={6} className="my-3">
          <div
            className="p-3 bg-white border rounded shadow-sm"
            style={{ height: '100%' }}
          >
            <div className="text-center">
              <b>Nilai Persediaan Barang Per Bulan Tahun {filter.year}</b>
            </div>
            {getNilaiPersediaan.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartLine
                  showLegend={false}
                  data={{
                    labels: monthOptions.map((val) => val.label),
                    datasets: [
                      {
                        data: monthOptions.map((val) => {
                          const findMonth =
                            getNilaiPersediaan?.data?.data?.find(
                              (el) =>
                                el?.bulan_mutasi?.toString() ===
                                val.value.toString(),
                            );

                          return findMonth?.total
                            ? parseFloat(findMonth.total)
                            : 0;
                        }),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>
        <Col md={6} className="my-3">
          <div className="p-3 bg-white border rounded shadow-sm">
            <div className="text-center">
              <b>
                10 Barang Paling Sering Diminta <br /> Bulan {filter.monthName}{' '}
                {filter.year}
              </b>
            </div>
            {getBarangSeringDiminta.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartBarHorizontal
                  showLegend={false}
                  height={200}
                  data={{
                    labels:
                      sortedBarangSeringDiminta?.map((val) => val?.nama_item) ??
                      [],
                    datasets: [
                      {
                        data:
                          sortedBarangSeringDiminta?.map((val) => val?.qty) ??
                          [],
                        maxBarThickness: 30,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) =>
                            number.getWithComa(val.raw, {
                              minimumFractionDigits: 0,
                            }),
                        },
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          callback: (val) =>
                            number.getWithComa(val, {
                              minimumFractionDigits: 0,
                            }),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

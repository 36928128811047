import { memo } from 'react';
import { dateConvert } from 'utilities2';

export const InfoRankItem = memo(
  ({ data, loading, startMonth, endMonth, year }) => {
    const date = dateConvert();

    return (
      <div className="bg-white rounded border shadow-sm px-4 py-2 mb-3">
        <div className="text-center text-nowrap">
          Customer Terbaik {date.getDetailMonth(startMonth)} s/d{' '}
          {date.getDetailMonth(endMonth)} {year}
        </div>
        <div>
          {loading ? (
            <div className="d-block text-center my-3">Memuat data . . .</div>
          ) : (
            <>
              <div className="d-flex align-items-center m-2">
                <div
                  className="d-flex align-items-center justify-content-center mr-2 rounded"
                  style={{
                    minHeight: 25,
                    minWidth: 25,
                    fontSize: 14,
                    background: '#FFD700',
                  }}
                >
                  1
                </div>
                <div style={{ fontSize: 14 }}>
                  {data?.[0]?.nama_customer ?? '-'}
                </div>
              </div>
              <div className="d-flex align-items-center m-2">
                <div
                  className="d-flex align-items-center justify-content-center mr-2 rounded"
                  style={{
                    minHeight: 25,
                    minWidth: 25,
                    fontSize: 14,
                    background: '#C0C0C0',
                  }}
                >
                  2
                </div>
                <div style={{ fontSize: 14 }}>
                  {data?.[1]?.nama_customer ?? '-'}
                </div>
              </div>
              <div className="d-flex align-items-center m-2">
                <div
                  className="d-flex align-items-center justify-content-center mr-2 rounded"
                  style={{
                    minHeight: 25,
                    minWidth: 25,
                    fontSize: 14,
                    background: '#CD7F32',
                  }}
                >
                  3
                </div>
                <div style={{ fontSize: 14 }}>
                  {data?.[2]?.nama_customer ?? '-'}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);

import { DashboardApi } from 'api';
import { Button, ChartBarVertical, ChartLine, Select } from 'components2';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoAlertOutline, IoCashOutline } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { dateConvert, rupiahConvert } from 'utilities2';
import { InfoItem } from './components';
import { monthOptions } from './constants';
import { generateYearOptions } from './helpers';

const date = dateConvert();
const rupiah = rupiahConvert();

export const DashboardMarketing = ({ setNavbarTitle }) => {
  const [filter, setFilter] = useState({
    month: date.getMonth(new Date()),
    monthName: date.getDetailMonth(new Date()),
    year: date.getYear(new Date()),
  });

  const getPenawaran = useQuery(
    ['dashboard', 'MKT', 'penawaran', filter.month, filter.year],
    () =>
      DashboardApi.get({
        modul: 'MKT',
        part: 'penawaran',
        bulan: filter.month,
        tahun: filter.year,
      }),
  );

  const getPenawaranTahunan = useQuery(
    ['dashboard', 'MKT', 'penawaran_tahunan', filter.year],
    () =>
      DashboardApi.get({
        modul: 'MKT',
        part: 'penawaran_tahunan',
        tahun: filter.year,
      }),
  );

  const getSPKTahunan = useQuery(
    ['dashboard', 'MKT', 'spk_tahunan', filter.year],
    () =>
      DashboardApi.get({
        modul: 'MKT',
        part: 'spk_tahunan',
        tahun: filter.year,
      }),
  );

  useEffect(() => {
    setNavbarTitle('Dashboard Marketing');
  }, []);

  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          month: filter.month,
          monthName: filter.monthName,
          year: filter.year,
        }}
        onSubmit={(val) => {
          setFilter({
            month: val.month,
            monthName: val.monthName,
            year: val.year,
          });
        }}
      >
        {({ values, setFieldValue, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              {/* Bulan */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Bulan</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={monthOptions}
                    defaultValue={monthOptions.find(
                      (val) => val.value.toString() === values.month.toString(),
                    )}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        month: val.value,
                        monthName: val.label,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={generateYearOptions}
                    defaultValue={generateYearOptions.find(
                      (val) => val.value.toString() === values.year.toString(),
                    )}
                    onChange={(val) => setFieldValue('year', val.value)}
                  />
                </div>
              </div>

              {/* Button */}
              <div>
                <Button text="Filter" className="px-4" onClick={handleSubmit} />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Purchase request */}
        <Col md={6} className="mb-3">
          <InfoItem
            loading={getPenawaran.isFetching}
            title="Total Penawaran Deal"
            icon={<IoCashOutline />}
            value={
              getPenawaran.data?.data?.deal
                ? rupiah.getWithComa(getPenawaran.data?.data?.deal)
                : '-'
            }
          />
        </Col>

        {/* Info Surat jalan */}
        <Col md={6} className="mb-3">
          <InfoItem
            loading={getPenawaran.isFetching}
            title="Total Penawaran Tidak Deal"
            icon={<IoAlertOutline />}
            value={
              getPenawaran.data?.data?.tidak_deal
                ? rupiah.getWithComa(getPenawaran.data?.data?.tidak_deal)
                : '-'
            }
          />
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6} className="my-3">
          <div
            className="p-3 bg-white border rounded shadow-sm"
            style={{ height: '100%' }}
          >
            <div className="text-center">
              <b>Nilai Penawaran Per Bulan Tahun {filter.year}</b>
            </div>

            {getPenawaranTahunan.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartBarVertical
                  data={{
                    labels: monthOptions.map((val) => val.label),
                    datasets: [
                      {
                        label: 'Penawaran Deal',
                        data: monthOptions.map((val) => {
                          const findMonth =
                            getPenawaranTahunan?.data?.data?.find(
                              (el) =>
                                el?.bulan_penawaran?.toString() ===
                                val.value.toString(),
                            );
                          return findMonth?.deal
                            ? parseFloat(findMonth.deal)
                            : 0;
                        }),
                        backgroundColor: '#0073b9',
                        maxBarThickness: 30,
                      },
                      {
                        label: 'Penawaran Tidak Deal',
                        data: monthOptions.map((val) => {
                          const findMonth =
                            getPenawaranTahunan?.data?.data?.find(
                              (el) =>
                                el?.bulan_penawaran?.toString() ===
                                val.value.toString(),
                            );
                          return findMonth?.belum_deal
                            ? parseFloat(findMonth.belum_deal)
                            : 0;
                        }),
                        backgroundColor: '#ed6914',
                        maxBarThickness: 30,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        position: 'bottom',
                      },
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>

        <Col md={6} className="my-3">
          <div
            className="p-3 bg-white border rounded shadow-sm"
            style={{ height: '100%' }}
          >
            <div className="text-center">
              <b>Nilai SPK Per Bulan Tahun {filter.year}</b>
            </div>
            {getSPKTahunan.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartLine
                  showLegend={false}
                  data={{
                    labels: monthOptions.map((val) => val.label),
                    datasets: [
                      {
                        data: monthOptions.map((val) => {
                          const findMonth = getSPKTahunan?.data?.data?.find(
                            (el) =>
                              el?.bulan_penawaran?.toString() ===
                              val.value.toString(),
                          );
                          return findMonth?.nominal_spk
                            ? parseFloat(findMonth.nominal_spk)
                            : 0;
                        }),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

import { dateConvert } from 'utilities2';

export const getMonthNameList = (startDate, endDate) => {
  const convert = dateConvert();
  const dateFilter = [startDate, endDate];

  const monthName = dateFilter.map((val) => {
    const monthValue = val; // Angka bulan (contoh: 10 untuk Oktober)
    const currentYear = new Date().getFullYear(); // Mendapatkan tahun saat ini
    const dayOfMonth = convert.getDay(new Date()); // Contoh hari 1, Anda bisa menggantinya sesuai kebutuhan

    const todayDate = convert.getDetailMonth(
      new Date(currentYear, monthValue - 1, dayOfMonth),
    );

    return todayDate;
  });

  return monthName;
};


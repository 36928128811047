export const sortBarangSeringDiminta = (data = []) => {
  return data?.sort((a, b) => {
    const parseA = parseInt(a.qty);
    const parseB = parseInt(b.qty);

    if (parseA < parseB) {
      return 1;
    }

    if (parseA > parseB) {
      return -1;
    }

    return 0;
  });
};

import { useQuery } from 'react-query';
import { DashboardApi } from 'api';
import { ChartBarVertical } from 'components2';
import { rupiahConvert } from 'utilities2';

export const ChartBarPerMonth = ({
  selectedMonth,
  startMonth,
  endMonth,
  year,
}) => {
  const rupiah = rupiahConvert();

  let awal =
    startMonth === undefined ? undefined : new Date(startMonth).getMonth() + 1;
  let akhir =
    endMonth === undefined ? undefined : new Date(endMonth).getMonth() + 1;
  let tahun = year;

  if (awal === undefined) {
    awal = new Date().getMonth() + 1;
  }
  if (akhir === undefined) {
    akhir = new Date().getMonth() + 1;
  }
  if (tahun === undefined) {
    tahun = new Date().getFullYear();
  }

  const getTotalPembelianPerhari = useQuery(
    [
      'dashboard',
      'SLS',
      'total_pembelian_perhari',
      selectedMonth,
      awal,
      tahun,
      akhir,
    ],
    () =>
      DashboardApi.get({
        modul: 'SLS',
        part: 'total_pembelian_perhari',
        bulan: selectedMonth.value,
        tahun: tahun,
      }),
  );

  const number = Number(selectedMonth.value);

  const baru = number.toString();

  return (
    <>
      {getTotalPembelianPerhari?.isFetching ? (
        <div className="d-flex align-items-center justify-content-center my-5">
          Memuat data . . .
        </div>
      ) : (
        <>
          <div className="text-center">
            <b>Total Pembelian Per Hari Bulan {selectedMonth.label}</b>
          </div>

          <ChartBarVertical
            key={selectedMonth.value}
            showLegend={false}
            data={{
              labels:
                getTotalPembelianPerhari?.data?.data[baru]?.tanggal_pembelian,
              datasets: [
                {
                  data: getTotalPembelianPerhari?.data?.data[baru]?.total,
                  maxBarThickness: 30,
                },
              ],
            }}
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (val) => rupiah.getWithComa(val.raw),
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: (val) => rupiah.getWithComaWithoutLabel(val),
                  },
                },
              },
            }}
          />
        </>
      )}
    </>
  );
};

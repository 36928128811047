import { ChartDoughnut } from 'components2';
import { memo } from 'react';

export const InfoItem = memo(
  ({
    title,
    percent,
    value = 100,
    loading,
    color,
    data,
    chartBgColor: backgroundColor,
  }) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center bg-white rounded border shadow-sm px-4 pt-2 pb-3"
        style={{ height: '100%' }}
      >
        <div className="position-relative">
          <div
            style={{
              position: 'absolute',
              top: 38,
              left:
                percent?.toString()?.length > 2
                  ? 28
                  : value?.toString()?.length > 2
                  ? 28
                  : 32,
              color: color,
            }}
          >
            <b style={{ fontSize: 14 }}>{!loading && `${percent ?? 0}%`}</b>
          </div>

          <ChartDoughnut
            height={90}
            width={90}
            data={{
              datasets: [
                {
                  data: data,
                  backgroundColor,
                },
              ],
            }}
          />
        </div>

        <div className="text-right px-3 py-2">
          <div>{title}</div>
          {loading ? (
            <small>Memuat data . . .</small>
          ) : (
            <b style={{ fontSize: 40, fontWeight: 1200, lineHeight: 1 }}>
              {value}
            </b>
          )}
        </div>
      </div>
    );
  },
);


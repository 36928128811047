// Vertical Stack Pagination

import ReactPaginate from 'react-paginate';
import { Select } from 'components';

export const Pagination = ({
  dataLength,
  onDataLengthChange,
  currentPage,
  totalPage,
  onPaginationChange,
  dataPage,
  dataCount,
  dataNumber,
  size = 'md',
}) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <small className="mb-2">
        Menampilkan {dataNumber} - {dataCount < dataPage ? dataCount : dataPage}{' '}
        dari {dataCount} data
      </small>

      <ReactPaginate
        pageCount={totalPage}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        containerClassName="pagination pagination-sm justify-content-center"
        pageLinkClassName="page-link"
        breakClassName="page-link"
        previousClassName="page-link"
        nextClassName="page-link"
        activeClassName="page-item active"
        disabledClassName="page-item disabled"
        previousLabel="&laquo;"
        nextLabel="&raquo;"
        onPageChange={onPaginationChange}
        initialPage={currentPage - 1}
        disableInitialCallback={true}
      />

      <small className="mt-1">Tampilkan :</small>

      <Select
        defaultValue={dataLength}
        style={{ width: '150px' }}
        onChange={onDataLengthChange}
      >
        <option value="10">10 Data</option>
        <option value="20">20 Data</option>
        <option value="50">50 Data</option>
        <option value="100">100 Data</option>
      </Select>
    </div>
  );
};

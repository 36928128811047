import Services from 'services';

class DashboardApi {
  async get(params) {
    const data = await Services.get('/hrdu/dashboard', { params });
    return data.data;
  }
}

export default new DashboardApi();


import { DashboardApi } from 'api';
import { ApprovalStatusButton, Table } from 'components';
import { DataStatus } from 'components2';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { DecimalConvert, TableNumber } from 'utilities';
import { getStatusProsesProduksi } from '../helpers';
import { Pagination } from './Pagination';

export const TableProsesProduksi = ({
  startMonth,
  endMonth,
  year,
  convertMonth,
}) => {
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
  });

  const getProsesProduksiPerbulan = useQuery(
    [
      'dashboard',
      'prd',
      'proses_produksi',
      startMonth,
      endMonth,
      year,
      paginationConfig.page,
      paginationConfig.dataLength,
    ],
    () =>
      DashboardApi.get({
        modul: 'PRD',
        part: 'proses_produksi',
        bulan: startMonth,
        bulan_sampai: endMonth,
        tahun: year,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      }),
  );

  return (
    <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
      <div className="m-2">
        <b>
          List Data Proses Produksi Bulan{' '}
          {`${convertMonth.startDate} - ${convertMonth.endDate}`}
          {` ${year}`}
        </b>
      </div>
      <Table>
        <thead>
          <tr>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              No.
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Informasi Produksi
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Item Produksi
            </th>
            <th
              width={150}
              style={{ fontSize: 12 }}
              className="text-center align-middle"
            >
              Qty. Produksi
            </th>
            <th
              style={{ fontSize: 12 }}
              className="p-1 text-center align-middle"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {getProsesProduksiPerbulan.isFetching ||
          !getProsesProduksiPerbulan?.data?.data?.data?.length > 0 ? (
            <tr>
              <td colSpan={5}>
                <DataStatus
                  loading={getProsesProduksiPerbulan.isFetching}
                  text={
                    getProsesProduksiPerbulan.isFetching
                      ? 'Memuat data . . .'
                      : getProsesProduksiPerbulan.isError
                      ? 'Data gagal dimuat'
                      : 'Tidak ada data'
                  }
                />
              </td>
            </tr>
          ) : (
            getProsesProduksiPerbulan.data?.data?.data?.map((val, index) => (
              <tr key={index}>
                <td
                  style={{ fontSize: 12 }}
                  className="p-1 align-middle text-center"
                >
                  {TableNumber(
                    paginationConfig?.page,
                    paginationConfig.dataLength,
                    index,
                  )}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.no_jobmix}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {val?.nama_item}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  {`${DecimalConvert(val?.qty_jobmix).getWithComa} pcs`}
                </td>
                <td style={{ fontSize: 12 }} className="p-1 align-middle">
                  <ApprovalStatusButton
                    variant={getStatusProsesProduksi(val.status_jobmix).variant}
                  >
                    {getStatusProsesProduksi(val.status_jobmix).label}
                  </ApprovalStatusButton>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {getProsesProduksiPerbulan.isFetching ||
      getProsesProduksiPerbulan.data?.data?.data_count === 0 ? (
        ''
      ) : (
        <Pagination
          size="sm"
          dataLength={paginationConfig?.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            getProsesProduksiPerbulan.data?.data?.data_count <
            paginationConfig.dataLength
              ? getProsesProduksiPerbulan.data?.data?.data_count
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={getProsesProduksiPerbulan.data?.data?.data_count}
          currentPage={paginationConfig.page}
          totalPage={getProsesProduksiPerbulan.data?.data?.total_page}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}
    </div>
  );
};

import { DashboardApi } from 'api';
import { Button, ChartBarVertical, ChartLine, Select } from 'components2';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { dateConvert, rupiahConvert } from 'utilities2';
import { InfoItem, TablePurchaseOrder } from './components';
import { monthOptions } from './constants';
import { generateYearOptions } from './helpers';

const date = dateConvert();
const rupiah = rupiahConvert();

export const DashboardProcurement = ({ setNavbarTitle }) => {
  const [filter, setFilter] = useState({
    month: date.getMonth(new Date()),
    monthName: date.getDetailMonth(new Date()),
    year: date.getYear(new Date()),
  });

  const dateSelected = `${filter.year}-${filter.month}-01`;

  const getTotalVendor = useQuery(['dashboard', 'PRO', 'total_vendor'], () =>
    DashboardApi.get({
      modul: 'PRO',
      part: 'total_vendor',
    }),
  );
  const getVendorResmi = useQuery(
    ['dashboard', 'PRO', 'total_vendor_resmi'],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'total_vendor_resmi',
      }),
  );
  const getCalonVendor = useQuery(
    ['dashboard', 'PRO', 'total_calon_vendor'],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'total_calon_vendor',
      }),
  );
  const getVendorTerbaik = useQuery(
    ['dashboard', 'PRO', 'vendor_terbaik', dateSelected],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'vendor_terbaik',
        bulan: filter.month,
        tahun: filter.year,
      }),
  );
  const getTotalPembelian = useQuery(
    ['dashboard', 'PRO', 'total_pembelian', filter.month, filter.year],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'total_pembelian',
        bulan: filter.month,
        tahun: filter.year,
      }),
  );
  const getTotalPembelianTahun = useQuery(
    ['dashboard', 'PRO', 'po_tahunan', filter.year],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'po_tahunan',
        tahun: filter.year,
      }),
  );
  const getTotalPembelianHari = useQuery(
    ['dashboard', 'PRO', 'po_harian', filter.month, filter.year],
    () =>
      DashboardApi.get({
        modul: 'PRO',
        part: 'po_harian',
        bulan: filter.month,
        tahun: filter.year,
      }),
  );

  useEffect(() => setNavbarTitle('Dashboard Procurement'), []);

  return (
    <>
      {/* Filter Section */}
      <Formik
        initialValues={{
          month: filter.month,
          monthName: filter.monthName,
          year: filter.year,
        }}
        onSubmit={(val) => {
          setFilter({
            month: val.month,
            monthName: val.monthName,
            year: val.year,
          });
        }}
      >
        {({ values, setFieldValue, setValues, handleSubmit }) => (
          <div className="d-flex justify-content-center justify-content-lg-end">
            <div className="d-flex flex-column flex-md-row">
              {/* Bulan */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Bulan</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={monthOptions}
                    defaultValue={monthOptions.find(
                      (val) => val.value.toString() === values.month.toString(),
                    )}
                    onChange={(val) =>
                      setValues({
                        ...values,
                        month: val.value,
                        monthName: val.label,
                      })
                    }
                  />
                </div>
              </div>

              {/* Tahun */}
              <div className="d-flex align-items-center mr-2 mb-2">
                <span className="mr-2">Tahun</span>
                <div style={{ width: 150 }}>
                  <Select
                    noMargin
                    options={generateYearOptions}
                    defaultValue={generateYearOptions.find(
                      (val) => val.value.toString() === values.year.toString(),
                    )}
                    onChange={(val) => setFieldValue('year', val.value)}
                  />
                </div>
              </div>

              {/* Button */}
              <div>
                <Button text="Filter" className="px-4" onClick={handleSubmit} />
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* Info Section */}
      <Row className="mt-3">
        {/* Info Total Vendor */}
        <Col className="mb-3">
          <InfoItem
            type="default"
            loading={getTotalVendor.isFetching}
            title="Total Vendor"
            percent={100}
            value={
              getTotalVendor.data?.data
                ? parseInt(getTotalVendor.data?.data)
                : '-'
            }
            data={[100]}
          />
        </Col>

        {/* Info Vendor Resmi */}
        <Col className="mb-3">
          <InfoItem
            loading={getVendorResmi.isFetching}
            title="Vendor Resmi"
            percent={
              getVendorResmi.data?.data?.vendor_resmi
                ? parseInt(getVendorResmi.data?.data?.vendor_resmi)
                : '-'
            }
            value={
              getVendorResmi.data?.data?.jumlah
                ? parseInt(getVendorResmi.data?.data?.jumlah)
                : '-'
            }
            data={[
              getVendorResmi.data?.data?.vendor_resmi
                ? parseInt(getVendorResmi.data?.data?.vendor_resmi)
                : '-',
              parseInt(100) -
                parseInt(
                  parseInt(getCalonVendor.data?.data?.calon_vendor) ?? 0,
                ),
            ]}
          />
        </Col>

        {/* Info Calon Vendor */}
        <Col className="mb-3">
          <InfoItem
            loading={getCalonVendor.isFetching}
            title="Calon Vendor"
            percent={
              getCalonVendor.data?.data?.calon_vendor
                ? parseInt(getCalonVendor.data?.data?.calon_vendor)
                : '-'
            }
            value={
              getCalonVendor.data?.data?.jumlah
                ? parseInt(getCalonVendor.data?.data?.jumlah)
                : '-'
            }
            data={[
              getCalonVendor.data?.data?.calon_vendor
                ? parseInt(getCalonVendor.data?.data?.calon_vendor)
                : '-',
              parseInt(100) -
                parseInt(
                  parseInt(getCalonVendor.data?.data?.calon_vendor) ?? 0,
                ),
            ]}
          />
        </Col>
      </Row>

      {/* Chart Section */}
      <Row>
        <Col md={6}>
          {/* Vendor Terbaik Section */}
          <div className="p-3 bg-white border rounded shadow-sm mb-3 mt-2">
            <b>
              Vendor Terbaik {filter.monthName} {filter.year}
            </b>
            {getVendorTerbaik.isFetching ? (
              <div className="d-block text-center my-3">Memuat data . . .</div>
            ) : (
              <>
                <div className="d-flex align-items-center m-2">
                  <div
                    className="d-flex align-items-center justify-content-center mr-2 rounded"
                    style={{
                      minHeight: 25,
                      minWidth: 25,
                      fontSize: 14,
                      background: '#FFD700',
                    }}
                  >
                    1
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {getVendorTerbaik?.data?.data?.[0]?.nama_vendor ?? '-'}
                  </div>
                </div>
                <div className="d-flex align-items-center m-2">
                  <div
                    className="d-flex align-items-center justify-content-center mr-2 rounded"
                    style={{
                      minHeight: 25,
                      minWidth: 25,
                      fontSize: 14,
                      background: '#C0C0C0',
                    }}
                  >
                    2
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {getVendorTerbaik?.data?.data?.[1]?.nama_vendor ?? '-'}
                  </div>
                </div>
                <div className="d-flex align-items-center m-2">
                  <div
                    className="d-flex align-items-center justify-content-center mr-2 rounded"
                    style={{
                      minHeight: 25,
                      minWidth: 25,
                      fontSize: 14,
                      background: '#CD7F32',
                    }}
                  >
                    3
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {getVendorTerbaik?.data?.data?.[2]?.nama_vendor ?? '-'}
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Total Pembelian Section */}
          <div className="p-3 bg-white border rounded shadow-sm mb-3 mt-2">
            <b>
              Total Pembelian {filter.monthName} {filter.year}
            </b>
            {getTotalPembelian.isFetching ? (
              <div className="d-block text-center my-3">Memuat data . . .</div>
            ) : (
              <>
                <div style={{ fontSize: 32 }}>
                  <b>
                    {getTotalPembelian.data?.data?.[0]?.total
                      ? rupiah.getWithComa(
                          getTotalPembelian.data?.data?.[0]?.total,
                        )
                      : '-'}
                  </b>
                </div>
              </>
            )}
          </div>

          {/* Table Section */}
          <TablePurchaseOrder
            month={filter.month}
            monthName={filter.monthName}
            year={filter.year}
          />
        </Col>

        <Col md={6}>
          {/* Chart Total Pembelian Bulan */}
          <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
            <div className="text-center">
              <b>Total Pembelian Per Bulan Tahun {filter.year}</b>
            </div>
            {getVendorTerbaik.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartLine
                  showLegend={false}
                  data={{
                    labels: monthOptions.map((val) => val.label),
                    datasets: [
                      {
                        data: monthOptions.map((val) => {
                          const findMonth =
                            getTotalPembelianTahun?.data?.data?.find(
                              (el) =>
                                el?.bulan_po?.toString() ===
                                val.value.toString(),
                            );

                          return findMonth?.total
                            ? parseFloat(findMonth.total)
                            : 0;
                        }),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>

          {/* Chart Total Pembelian Hari*/}
          <div className="p-3 bg-white border rounded shadow-sm mb-4 mt-2">
            <div className="text-center">
              <b>
                Total Pembelian Per Hari Bulan {filter.monthName} {filter.year}
              </b>
            </div>
            {getTotalPembelianHari.isFetching ? (
              <div className="d-flex align-items-center justify-content-center my-5">
                Memuat data . . .
              </div>
            ) : (
              <>
                <ChartBarVertical
                  showLegend={false}
                  data={{
                    labels: getTotalPembelianHari.data?.data?.map(
                      (val) => val?.harian ?? '-',
                    ),
                    datasets: [
                      {
                        data: getTotalPembelianHari.data?.data?.map(
                          (val) => val?.total ?? 0,
                        ),
                        maxBarThickness: 30,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (val) => rupiah.getWithComa(val.raw),
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          callback: (val) => rupiah.getWithComa(val),
                        },
                      },
                    },
                  }}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};
